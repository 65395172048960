define("discourse/plugins/discourse-plugin-composer-help-button/discourse/components/composer-help-content", ["exports", "@ember/component", "discourse/mixins/buffered-content"], function (_exports, _component, _bufferedContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ComposerHelpContentComponent = _component.default.extend((0, _bufferedContent.bufferedProperty)("model"), {
    tagName: "div"
  });

  var _default = ComposerHelpContentComponent;
  _exports.default = _default;
});
define("discourse/plugins/discourse-plugin-composer-help-button/discourse/controllers/composer-help", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/ajax"], function (_exports, _modalFunctionality, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    loading: true,
    parsedContent: '',
    refresh: function refresh() {
      this.set("loading", true);
    },
    init: function init() {
      this._super();

      this.setProperties({
        "loading": true,
        "parsedContent": ''
      });

      _ajax.default.ajax(Discourse.SiteSettings.composer_help_modal_url + '.json').then(function (resp) {
        this.set('parsedContent', resp.post_stream.posts[0].cooked);
        this.refresh();
      }.bind(this));
    }
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-plugin-composer-help-button/discourse/initializers/composer-help", ["exports", "discourse/lib/plugin-api", "discourse/components/d-editor", "discourse/lib/show-modal"], function (_exports, _pluginApi, _dEditor, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function initializePlugin(api) {
    var siteSettings = api.container.lookup('site-settings:main');

    if (siteSettings.composer_help_enabled && siteSettings.composer_help_modal_url) {
      _dEditor.default.reopen({
        actions: {
          showComposerHelp: function showComposerHelp() {
            (0, _showModal.default)('composer-help', {
              title: 'composer_help.title'
            });
          }
        }
      });

      api.onToolbarCreate(function (toolbar) {
        toolbar.addButton({
          id: "composer_help_button",
          group: "extras",
          icon: "question",
          action: 'showComposerHelp'
        });
      });
    }
  }

  var _default = {
    name: 'composer-help',
    initialize: function initialize(container) {
      (0, _pluginApi.withPluginApi)('0.1', function (api) {
        return initializePlugin(api);
      });
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/composer-help-content"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[24,[\"buffered\",\"parsedContent\"]],true],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/composer-help-content"}});
Ember.TEMPLATES["javascripts/modal/composer-help"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"id\"],[\"composer-help-modal\"]],{\"statements\":[[4,\"if\",[[24,[\"loadingTags\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"i18n\",[[24,[\"loading\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"composer-help-content\"],[8],[0,\"\\n        \"],[1,[28,\"composer-help-content\",null,[[\"model\"],[[23,0,[]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/modal/composer-help"}});

